.haup-card-layout {
  padding: 1rem;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  gap: 1rem;
  padding-top: 4rem;
  position: relative;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.haup-card-layout:before {
  position: absolute;
  inset: 0;
  padding-block: 1rem;
  font-size: 1rem;
  padding-left: 0.5rem;
  inset: -1%;
  content: attr(data-title);
  width: 102%;
  font-weight: 700;
  text-align: left;
  padding-left: 4%;
  color: white;
  height: fit-content;
  background-color: var(--haup-primary);
}

.haup-card-layout:after {
  content: attr(data-status);
  position: absolute;
  text-align: right;
  top: 15px;
  font-size: 0.9rem;
  right: 2%;
  /* transform: translateX(-3rem); */
  width: 100%;
  font-weight: 700;
  font-style: italic;
  color: white;
}

.haup-card-layout.light:before {
  color: var(--haup-primary);
  background-color: var(--haup-gray);
}

.haup-card-layout.light:after {
  color: var(--haup-primary);
  background-color: var(--haup-gray);
}

.haup-card-layout p {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-inline: 0.5rem;
  justify-content: space-between;
}

.haup-card-layout p:first-child {
  margin-top: 1rem;
}

.haup-card-layout button {
  color: var(--haup-primary);
  font-weight: 700;
  border-bottom: 1px solid var(--haup-primary);
}
