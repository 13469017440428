.logo {
  height: 40px;
  margin: 16px;
  text-align: center;
  align-items: center;
  /* background: rgba(255, 255, 255, 0.3); */
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}

.site-page-header {
  background: white;
}

.rightNav {
  text-align: right;
  color: white;
  line-height: 1.2;
  margin: auto auto;
  font-size: 0.8rem;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: unset;
  text-align: center;
}

#root {
  height: 100%;
}

.datePickerStyle {
  :global {
    .ant-picker-now {
      display: none;
    }
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid rgb(0, 0, 0);
  z-index: 1500;
}

.row-dragging td {
  padding: 16px;
  z-index: 1500;
}

.row-dragging .drag-visible {
  visibility: visible;
  z-index: 1500;
}

@layout-header-background: #76c0f9;