@import './css/_base.scss';
@import './css/_variables.scss';
@import './css/_utilities.scss';
@import './css/vehicle-report';
@import './css/ui-report';
@import './css/haup-card-layout';

// Size breakpoints for screen
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-template-rows: repeat(auto, max-content);
  @media only screen and (max-width: 1200px) {
    font-size: 87%;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }

  @media only screen and (max-width: 900px) {
    font-size: 75%;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  &__item {
  }

  &__item-large {
    grid-row: span 3;
    order: 0;
    @media only screen and (max-width: 1200px) {
      order: 99;
    }
  }
}

.site-layout-background {
  position: relative;
  @media only screen and (max-width: $phone) {
    padding: 12px !important;
  }
}

// .ant-table-wrapper {
//   overflow-y: scroll;
// }

.ant-layout {
  overflow: hidden;
  @media only screen and (max-width: 700px) {
    flex-direction: column !important;

    > .ant-layout-content {
      margin: 0 !important;
    }
    > .site-layout {
      width: unset !important;
    }

    > .ant-layout-header {
      padding: 0 16px;
    }
  }

  @media only screen and (max-width: 500px) {
    > .ant-layout-header {
      font-size: 12px;
      h1 {
        font-size: 16px;
      }

      p {
        margin-bottom: 0;
        line-height: 1.2;
      }
    }
  }
}

.ant-layout-sider {
  @media only screen and (max-width: 700px) {
    width: 100% !important;
    flex: 1 !important;
    max-width: unset !important;
  }
}
.ant-layout-sider-children {
  @media only screen and (max-width: 700px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;
    overflow: hidden;
    > .logo {
      margin: 0;
    }
    > .ant-menu-inline {
      width: unset;
    }

    > .ant-menu-item {
      display: flex !important;
      align-items: center !important;
    }

    > ul {
      display: none !important;
    }
  }
}
.ant-layout-sider-trigger {
  @media only screen and (max-width: 700px) {
    display: none !important;
  }
}
.ant-layout-sider-has-trigger {
  @media only screen and (max-width: 700px) {
    padding: 0;
  }
}

.hamburger {
  display: none;
  @media only screen and (max-width: 700px) {
    display: block;

    ul .ant-menu-inline {
      background: none !important;
    }

    .toggleHamburger {
      cursor: pointer;
      z-index: 9999;
      background: $color-primary;
      border-radius: 50%;
      position: fixed;
      bottom: 5vh;
      right: 5vw;
      width: 2.5rem;
      height: 2.5rem;
    }

    &__icon,
    &__icon::before,
    &__icon::after {
      background: $color-white;
      position: absolute;
      display: block;
      height: 1.2px;
      left: 50%;
      transition: all 0.5s ease;
      transform: translate(-50%, -50%);
    }

    &__icon {
      width: 50%;
      top: 50%;
    }

    &__icon::before,
    &__icon::after {
      content: '';
      width: 100%;
    }

    &__icon::before {
      top: -450%;
    }
    &__icon::after {
      top: 550%;
    }

    &__bg {
      background: radial-gradient(circle at 100%, black, blue);
      position: fixed;
      z-index: 99;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      bottom: 5vh;
      right: 5vw;
      opacity: 0;
      transition: all 0.3s cubic-bezier(1, 0.01, 0.56, 0.68);
    }

    &__nav {
      position: fixed;
      top: 50%;
      right: 0;
      z-index: 999999;
      opacity: 0;
      width: 0;
      transition: all 0.6s ease;
      transform: translate(-50%, -50%);
    }
  }
  input:checked ~ .hamburger__bg {
    opacity: 1;
    transform: scale(60);
  }

  input:checked ~ .hamburger__nav {
    left: 50%;
    width: 66%;
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  input:checked ~ .toggleHamburger > .hamburger__icon {
    background: transparent;

    &::before {
      transform: translate(-50%, 500%) rotate(225deg);
    }

    &::after {
      transform: translate(-50%, -500%) rotate(-225deg);
    }
  }
}

.heading {
  margin-bottom: 1rem;
  &__details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
  }

  &__progress {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
.form {
  border: 1px solid $color-primary;
  border-radius: 0.8rem;
  overflow: hidden;
  flex: 1;
  &__content {
    padding: 1rem;
  }

  &__content .ant-space-vertical {
    width: 100%;
  }

  &__title {
    padding: 0.5rem 1rem;
    background: rgba($color-primary, 0.8);
    color: $color-white !important;
  }
}

.form__space {
  display: flex;
  flex: 1;
  gap: 0.5rem;

  & .ant-space-item {
    flex: 1;
    width: 100%;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
}

.form__space--grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  & .ant-space-item {
    margin: 0 !important;
  }
}

.label-top > .ant-space-item:first-of-type {
  align-self: flex-start;
}

.content {
  display: flex;
  flex: 0 1;
  @media only screen and (max-width: $tablet) {
    flex-direction: column;
  }
}

.content-right {
  width: 0;
  position: relative;
  transition: all 0.5s ease;
  overflow: hidden;
  @media only screen and (max-width: $tablet) {
    width: 100%;
  }
}

.content-right__action {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  padding: 1rem;
  width: calc((100vw - 200px - 32px - 48px - 16px) / 2);
  @media only screen and (max-width: $tablet) {
    padding: 1rem 0;
    width: 100%;
  }
}

.button-group {
  margin: 1rem 0;
  display: flex;
  gap: 2rem;
  align-items: center;

  > button {
    min-width: 100px;
    justify-content: center;
  }
}
.expanded {
  flex: 1;
}
.expanded__fixed {
  position: fixed;
  top: 10rem;
}

.expand-button {
  @media only screen and (max-width: $tablet) {
    display: none !important;
  }
}

.font-primary {
  color: rgba($color-primary, 0.8);
}

h4.ant-typography.title-4 {
  margin: 1.5rem 0 0.5rem 0;
  color: rgba($color-primary, 0.8);
}
h5.ant-typography.title-5 {
  color: rgba($color-primary, 0.8);
}

.mb-0 {
  margin-bottom: 0;
}

.ant-statistic-title {
  text-transform: capitalize;
}

.admin-dashboard-table .ant-table .ant-table-tbody > tr:hover > td {
  background: rgba(54, 162, 235, 0.08);
}

.demo-wrapper {
  // background: red;
}

.demo-editor {
  padding: 6px 5px;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  height: 15rem;
}

.card {
  position: relative;
  &:hover {
    > .card__face--front {
      transform: rotateY(180deg);
      z-index: 0;
    }
    > .card__face--back {
      transform: rotateY(0deg);
      z-index: 1;
    }
  }
  &__face {
    backface-visibility: hidden;
    height: 100%;
    flex: 1;
    inset: 0 0 0 0;
    transition: all 1s ease;
    position: absolute;

    &--front {
      z-index: 1;
      background: red;
    }

    &--back {
      background: blue;
      transform: rotateY(-180deg);
      z-index: 0;
    }
  }
}

.form__image {
  position: relative;
  overflow: hidden;
  transition: filter 0.5s ease;
  &:hover {
    > img {
      filter: brightness(0.66);
    }
    > span {
      top: 50%;
    }
  }

  > img {
  }

  > span {
    color: #fff;
    text-align: center;
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: -50px;
    transform: translate(-50%, 0);
    line-height: 1;
    transition: top 0.5s ease-out;
    background: red;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 50%;
  }
}

.ant-layout-sider-collapsed {
  .sideMenu li {
    display: flex;
    align-items: center;
    justify-content: center;

    > span[role='img'] > span[role='img'] > svg {
      font-size: 1.5rem;
    }
  }
}
.hamburger__nav li,
.sideMenu li {
  display: flex;
  align-items: center;
  > span[role='img'] > span[role='img'] > svg {
    font-size: 1.6rem;
  }
}

.hover-effect:hover {
  background: rgba(54, 162, 235, 0.08);
  cursor: pointer;
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}
div {
  .snow {
    $total: 200;
    position: fixed;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
    z-index: 500;

    @for $i from 1 through $total {
      $random-x: random(1000000) * 0.0001vw;
      $random-offset: random_range(-100000, 100000) * 0.0001vw;
      $random-x-end: $random-x + $random-offset;
      $random-x-end-yoyo: $random-x + ($random-offset / 2);
      $random-yoyo-time: random_range(30000, 80000) / 100000;
      $random-yoyo-y: $random-yoyo-time * 100vh;
      $random-scale: random(10000) * 0.0001;
      $fall-duration: random_range(10, 30) * 1s;
      $fall-delay: random(30) * -1s;

      &:nth-child(#{$i}) {
        opacity: random(10000) * 0.0001;
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
      }

      @keyframes fall-#{$i} {
        #{percentage($random-yoyo-time)} {
          transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
        }

        to {
          transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
        }
      }
    }
  }
}

// .ant-upload-list-picture-card-container {
//   width: 100%;
//   height: 100%;
// }

.pad-content {
  padding: 1rem;
}

#menuToggle {
  display: block;
  position: relative;
  top: 9px;
  left: 20px;
  padding: 8px;
  z-index: 1;
  background-color: #76c0f9;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 25px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4) !important;
}

// #menuToggle a
// {
//   text-decoration: none;
//   color: #232323;

//   transition: color 0.3s ease;
// }

// #menuToggle a:hover
// {
//   color: tomato;
// }anticon ant-menu-item-icon

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  background-color: #f5f5f5;
  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

#menuToggle {
  .anticon .ant-menu-item-icon {
    display: none;
  }
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: rgb(21, 73, 155);
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
  // display: none;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #76c0f9;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 323px;
  margin: -44px 0 0 -68px;
  padding: 50px;
  padding-top: 125px;
  background: #15499b;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  max-height: 111.5vh;
  transform-origin: 0% 0%;
  transform: translate(-106%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  overflow-y: scroll;
  height: 900px;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul {
  transform: none;
}

.site-bar-media {
  @media screen and (max-width: $lg) {
    display: none !important;
  }
}

.show_menu_toggle {
  @media screen and (min-width: $md) {
    display: none !important;
  }
}

.ant-menu-title-content a {
  color: #ffffff;
}

.hidden {
  display: none;
}
